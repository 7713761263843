import * as ComponentConstants from '../constants/component.constant';

const initialState = {
	appLoader: false,
	sideNavMode: false,
	mobileNavMode: false,
	navCollapsibleMode: false,
	themeMode: "",
	toasts: [],
};

const componentReducer = (state = initialState, action) => {
	switch (action.type) {

		case ComponentConstants.LOADING_APP:
			return { ...state, appLoader: action.payload };

		case ComponentConstants.TOGGLE_MOBILE_SIDEBAR_STATE:
			return { ...state, sideNavMode: action.payload };

		case ComponentConstants.TOGGLE_MOBILE_NAVBAR_STATE:
			return { ...state, mobileNavMode: action.payload }
				;
			case ComponentConstants.TOGGLE_NAVBAR_COLLAPSE_STATE:
			return { ...state, navCollapsibleMode: action.payload };

		case ComponentConstants.ADD_TOAST:
			return {
				...state,
				toasts: [...state.toasts, action.payload]
			};

		case ComponentConstants.DELETE_TOAST:
			const updatedToasts = state.toasts.filter(toast => toast.id !== action.payload);
			return {
				...state,
				toasts: updatedToasts,
			};

		case 'RESET_STATE':
			return initialState;

		default:
			return state;
	}
};

export default componentReducer;
