import ActionCreator from "../../utils/helpers/actionCreator";
import * as ComponentConstants from "../constants/component.constant";


export const loadingApp = data => async dispatch => {
	dispatch(ActionCreator(ComponentConstants.LOADING_APP, data));
};

export const toggleSideMenu = data => async dispatch => {
	dispatch(ActionCreator(ComponentConstants.TOGGLE_MOBILE_SIDEBAR_STATE, data));
};

export const toggleMobileNav = data => async dispatch => {
	dispatch(ActionCreator(ComponentConstants.TOGGLE_MOBILE_NAVBAR_STATE, data));
};

export const toggleCollapsibleNav = data => async dispatch => {
	dispatch(ActionCreator(ComponentConstants.TOGGLE_NAVBAR_COLLAPSE_STATE, data));
};

export const addToast = data => async dispatch => {
	const id = Math.floor(Math.random() * 1000000).toString().concat("_", Date.now().toString());
	dispatch(ActionCreator(ComponentConstants.ADD_TOAST, { id, ...data }));
}

export const removeToast = id => async dispatch => {
	dispatch(ActionCreator(ComponentConstants.DELETE_TOAST, id));
};
